@import '~antd/dist/antd.less';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-y: hidden;
}

select {
  -webkit-appearance: none;
  min-width: 150px;
}

.react-datepicker-popper {
  z-index: 5;
}

.week-number-datepicker .react-datepicker-wrapper input {
  width: 100%;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #aaa;
}

:root {
  --toastify-color-success: #3D714E;
}

@primary-color: #3D714E;@menu-item-active-bg: #DCECE1;@font-size-base: 16px;